import React, { Component } from 'react';
import GameElementSelector from './ElementSelector';
import { Conjunction, GameElement, RuleType } from '../../utils/enum';
import PropTypes from 'prop-types';
import ConjunctionIndicator from '../../controls/ConjunctionIndicator';
import Image, { GetImageSrc } from '../../controls/Image';

class GameQualifications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ruleSelectionIsOpen: false,
        };
    }

    ensureQualificationRuleUnion = () => {
        // There can be one or two unions: one for ranking rules (type=Leaderboard), one for qualification rules (type=Game).
        // The kind of union is determined by the type of the rules it contains (or undefined if the list of rules is empty).

        // Return the union that contains only rules of type Game.
        // If there is no such union, check if there is an empty union and return it.
        // If there is no empty union, create a new one and return it.

        const { game } = this.props;

        let qualificationRuleUnion = game.ruleUnions.find(ru => ru.rules.every(r => r.type === RuleType.Game));

        if (!qualificationRuleUnion) {
            qualificationRuleUnion = game.ruleUnions.find(ru => ru.rules.length === 0);
        }

        if (!qualificationRuleUnion) {
            qualificationRuleUnion = {
                id: new Date().getMilliseconds(),
                participantId: null,
                rules: [],
                ruleUnions: [],
                games: [],
                conjunction: Conjunction.Then,
                childrenConjunction: Conjunction.Then
            };
            game.ruleUnions.push(qualificationRuleUnion);
        }

        return qualificationRuleUnion;
    };

    onSelectRuleBegin = () => {
        this.setState({ ruleSelectionIsOpen: true })
    };

    onSelectRuleEnd = (rule) => {
        const { onChange } = this.props;

        const qualificationRuleUnion = this.ensureQualificationRuleUnion();

        qualificationRuleUnion.rules.push({
            ...rule,
            unionEntityId: new Date().getMilliseconds()
        });

        this.setState({ ruleSelectionIsOpen: false });
        onChange();
    };

    onSelectRuleCancel = () => {
        this.setState({ ruleSelectionIsOpen: false });
    };

    onRemoveRule = (rule) => {
        const { onChange } = this.props;

        const qualificationRuleUnion = this.ensureQualificationRuleUnion();
        const index = qualificationRuleUnion.rules.indexOf(rule);

        if (index !== -1) {
            qualificationRuleUnion.rules.splice(index, 1);
            onChange();
        }
    };

    render() {
        const { game, onClick, onAdd } = this.props;
        const { ruleSelectionIsOpen } = this.state;

        const activeParticipant = game.gameParticipants.find(p => p.participant.isActive) ?? null;

        const qualificationRuleUnion = this.ensureQualificationRuleUnion();
        const qualificationRules = qualificationRuleUnion.rules;

        return (
            <>
                <div className={activeParticipant ? 'union-title warning' : 'union-title valid'}>
                    <i className="glyphicon glyphicon-stats"/>
                    Qualification
                    <span className="warning">{activeParticipant ? 'Default player profile used!' : ''}</span>
                </div>

                {!activeParticipant && (
                    <ul className="tree">
                        {qualificationRules.map((rule, index) => (
                            <li key={rule.id}>
                                {index > 0 && <ConjunctionIndicator conjunction={Conjunction.And}/>}

                                <button type="button"
                                        className="reward"
                                        title={rule.name}
                                        onClick={() => onClick(rule, GameElement.Rules)}>
                                    <Image src={GetImageSrc(rule.imageId, 40)} alt="rule"/>
                                    {rule.name}
                                </button>

                                {!game.publishedOn && (
                                    <button type="button"
                                            className="btn-remove"
                                            title="Delete"
                                            onClick={() => this.onRemoveRule(rule)}>
                                        <i className="glyphicon glyphicon-remove"/>
                                    </button>
                                )}
                            </li>
                        ))}
                    </ul>
                )}

                {!game.publishedOn && !activeParticipant && (qualificationRules.length === 0) && (
                    <ul className="tree subtree">
                        <li>
                            <button type="button" className="reward-new" onClick={this.onSelectRuleBegin}>
                                Add...
                            </button>
                        </li>
                    </ul>
                )}

                <GameElementSelector isOpen={ruleSelectionIsOpen}
                                     mode={GameElement.Rules}
                                     existing={[]}
                                     multiple={false}
                                     onClose={this.onSelectRuleCancel}
                                     onSelect={(rule) => this.onSelectRuleEnd(rule)}
                                     onAdd={(rule) => onAdd(qualificationRules, GameElement.Rules, rule)} />
            </>
        );
    }
}

GameQualifications.propTypes = {
    game: PropTypes.shape({ id: PropTypes.number }).isRequired,
    onChange: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default GameQualifications;
