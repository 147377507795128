import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GameElement, Conjunction, RuleType } from '../../utils/enum';
import Image, { GetImageSrc } from '../../controls/Image';
import GameElementSelector from './ElementSelector';
import ConjunctionIndicator from '../../controls/ConjunctionIndicator';

class GameRankings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rankingSelectionIsOpen: false,
    };
  }

  ensureRankingRuleUnion = () => {
    // There can be one or two unions: one for ranking rules (type=Leaderboard), one for qualification rules (type=Game).
    // The kind of union is determined by the type of the rules it contains (or undefined if the list of rules is empty).

    // Return the union that contains only rules of type Leaderboard.
    // If there is no such union, check if there is an empty union and return it.
    // If there is no empty union, create a new one and return it.

    const { game } = this.props;

    let rankingRuleUnion = game.ruleUnions.find(ru => ru.rules.every(r => r.type === RuleType.Leaderboard));

    if (!rankingRuleUnion) {
      rankingRuleUnion = game.ruleUnions.find(ru => ru.rules.length === 0);
    }

    if (!rankingRuleUnion) {
      rankingRuleUnion = {
        id: new Date().getMilliseconds(),
        participantId: null,
        rules: [],
        ruleUnions: [],
        games: [],
        conjunction: Conjunction.Then,
        childrenConjunction: Conjunction.Then
      };
      game.ruleUnions.push(rankingRuleUnion);
    }

    return rankingRuleUnion;
  };

  onSelectRuleBegin = () => {
    this.setState({ rankingSelectionIsOpen: true })
  };

  onSelectRuleEnd = (rule) => {
    const { onChange } = this.props;

    const rankingRuleUnion = this.ensureRankingRuleUnion();

    rankingRuleUnion.rules.push({
      ...rule,
      unionEntityId: new Date().getMilliseconds()
    });

    this.setState({ rankingSelectionIsOpen: false });
    onChange();
  };

  onSelectRuleCancel = () => {
    this.setState({ rankingSelectionIsOpen: false });
  };

  onRemoveRule = (rule) => {
    const { onChange } = this.props;

    const rankingRuleUnion = this.ensureRankingRuleUnion();
    const index = rankingRuleUnion.rules.indexOf(rule);

    if (index !== -1) {
      rankingRuleUnion.rules.splice(index, 1);
      onChange();
    }
  };

  render() {
    const { game, onAdd, onClick } = this.props;
    const { rankingSelectionIsOpen } = this.state;

    const activeParticipant = game.gameParticipants.find(p => p.participant.isActive) ?? null;

    const rankingRuleUnion = this.ensureRankingRuleUnion();
    const rankingRules = rankingRuleUnion.rules;

    const hasAnyRanking = () => rankingRules.length > 0;

    return (
      <>
        <div className={activeParticipant ? 'union-title warning' : (hasAnyRanking() ? 'union-title valid' : 'union-title error')}>
          <i className="glyphicon glyphicon-stats" />
          Ranking
          <span className="error">{!hasAnyRanking() && !activeParticipant ? 'Leaderboard must contain a ranking!' : ''}</span>
          <span className="warning">{activeParticipant ? 'Default player profile used!' : ''}</span>
        </div>
        <ul className="tree">
          {!activeParticipant && rankingRules.map((ranking, index) => (
            <li key={`reward-${ranking.id}`}>
              {index > 0 && <ConjunctionIndicator conjunction={Conjunction.And} />}
              <button type="button" onClick={() => onClick(ranking, GameElement.Rankings)}
                className="reward" title={ranking.name}>
                <Image src={GetImageSrc(ranking.imageId, 40)} alt="ranking" />
                {ranking.name}
              </button>

              {!game.publishedOn && !activeParticipant && (
                <button type="button"
                  className="btn-remove"
                  title="Delete"
                  onClick={() => this.onRemoveRule(ranking)}>
                  <i className="glyphicon glyphicon-remove" />
                </button>
              )}
            </li>
          ))}
        </ul>

        {!game.publishedOn && !activeParticipant && rankingRules.length === 0 && (
          <ul className="tree subtree">
            <li>
              <button type="button" className="reward-new" onClick={this.onSelectRuleBegin}>
                Add...
              </button>
            </li>
          </ul>
        )}

        <GameElementSelector isOpen={rankingSelectionIsOpen}
          mode={GameElement.Rankings}
          existing={[]}
          multiple={false}
          onClose={this.onSelectRuleCancel}
          onSelect={(rule) => this.onSelectRuleEnd(rule)}
          onAdd={(rule) => onAdd(rankingRules, GameElement.Rankings, rule)} />
      </>
    );
  }
}

GameRankings.propTypes = {
  game: PropTypes.shape({ id: PropTypes.number }).isRequired,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default GameRankings;
