import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Switch from 'rc-switch';
import { AnimatePresence } from 'framer-motion';
import Popup from 'reactjs-popup';
import { actionCreators as gameActionCreators } from '../../store/modules/Game';
import { actionCreators as leaderboardActionCreators } from '../../store/modules/Leaderboard';
import { actionCreators as ruleActionCreators } from '../../store/modules/Rule';
import { actionCreators as rankingActionCreators } from '../../store/modules/Ranking';
import { actionCreators as rewardActionCreators } from '../../store/modules/Reward';
import { actionCreators as participantActionCreators } from '../../store/modules/Participant';
import './Edit.css';
import confirm, { NoCancel } from '../../utils/confirm';
import BackButton from '../../controls/BackButton';
import DeleteButton from '../../controls/DeleteButton';
import GameRewards from './Rewards';
import GameSettings from './Settings';
import GameParticipants from './Participants';
import GameRules from './Rules';
import GameRankings from './Rankings';
import GameQualifications from './Qualifications';
import GameElementEditors from './ElementEditors';
import GameElementSelector from './ElementSelector';
import ImageUpload from '../../controls/ImageUpload';
import { Conjunction, GameElement } from '../../utils/enum';
import SaveButton from '../../controls/SaveButton';
import CopyButton from '../../controls/CopyButton';
import GameRoundList from './RoundList';
import date from '../../utils/date';
import animate from '../../utils/animate';
import GameSimulation from './Simulation';
import helper from '../../utils/helper';
import Image, { GetImageSrc } from '../../controls/Image';
import GameRuleNotification from './RuleNotification';
import JourneyEditor from "../journey/JourneyEditor";

class GameEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      game: {
        name: props.isGameMode ? 'New Game' : 'New Leaderboard',
        maxOccurrencesPlayer: props.isGameMode ? 1 : null,
        maxOccurrencesGlobal: null,
        startOn: '',
        endOn: '',
        ruleUnions: [],
        rewards: [],
        participantUnions: [],
        rewardUnions: [],
        attributes: {},
        gameParticipants: [],
        gameNotifications: [],
      },
      participantSelectionIsOpen: false,
      advancedMode: false,
      gameElementToAdd: null,
      arrayToAdd: null,
      elementToEdit: null,
      gameNotificationToEdit: null,
      simulate: false,
      simulatedActions: { actions: [{ key: 0 }] },
      section: 'general',
    };
  }

  componentDidMount() {
    const { id, requestGet, hideChildFilters } = this.props;

    if (id) {
      requestGet(id);
      hideChildFilters();
    } else {
      this.addMissingUnions();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { gameElementToAdd, arrayToAdd, dataLoaded } = this.state;
    const {
      id, copy, reward, rule, participant, ranking, requestAllRules, requestAllRankings, requestAllRewards, requestAllParticipants, game
    } = this.props;

    if (!prevState.dataLoaded && !dataLoaded) {
      requestAllRules();
      requestAllRankings();
      requestAllRewards();
      requestAllParticipants();
      this.setState({ dataLoaded: true });
    }

    if (id && game && game !== prevProps.game) {
      this.setState(
        {
          game: copy ? { ...game, publishedOn: null, name: `Copy of ${game.name}` } : game,
          advancedMode: this.gameRequiresAdvancedMode(game),
        },
        () => this.addMissingUnions(),
      );
    }

    if (gameElementToAdd === GameElement.Rewards && reward && reward !== prevProps.reward) {
      if (arrayToAdd) arrayToAdd.push(reward);
      else this.updateGameElement(reward, gameElementToAdd);
      this.handleClickGameElement(null, null);
    } else if (gameElementToAdd === GameElement.Rules && rule && rule !== prevProps.rule) {
      if (arrayToAdd) arrayToAdd.push(rule);
      else this.updateGameElement(rule, gameElementToAdd);
      this.handleClickGameElement(null, null);
    } else if (gameElementToAdd === GameElement.Rankings && ranking && ranking !== prevProps.ranking) {
      if (arrayToAdd) arrayToAdd.push(ranking);
      else this.updateGameElement(ranking, gameElementToAdd);
      this.handleClickGameElement(null, null);
    } else if (gameElementToAdd === GameElement.Participants && participant && participant !== prevProps.participant) {
      if (arrayToAdd) arrayToAdd.push(participant);
      else this.updateGameElement(participant, gameElementToAdd);
      this.handleClickGameElement(null, null);
    }
  }

  updateGameElement = (element, gameElementToAdd) => {
    const { game } = this.state;

    if (gameElementToAdd === GameElement.Rewards) {
      game.rewards.forEach(reward => {
        this.updateGameElementProps(reward, element);
      });
    } else if (gameElementToAdd === GameElement.Rules || gameElementToAdd === GameElement.Rankings) {
      game.ruleUnions.forEach(union => {
        union.rules.forEach(rule => {
          this.updateGameElementProps(rule, element);
        });
      });
    } else if (gameElementToAdd === GameElement.Participants) {
      game.participantUnions.forEach(union => {
        union.participants.forEach(participant => {
          this.updateGameElementProps(participant, element);
        });
      });
    }

    this.setState({ game });
  };

  updateGameElementProps = (target, source) => {
    if (target && source && target.id === source.id) {
      target.name = source.name;
      target.imageId = source.imageId;
    }
  };

  addMissingUnions = () => {
    const { isGameMode } = this.props;
    const { game } = this.state;

    [...game.gameParticipants.map(p => p.participant.id), null].forEach(id => {
      if (game.ruleUnions.filter(ru => ru.participantId === id).length === 0) {
        game.ruleUnions.push({
          id: new Date().getMilliseconds(),
          participantId: id,
          rules: [],
          ruleUnions: [],
          games: [],
          conjunction: Conjunction.Then,
          childrenConjunction: Conjunction.Then,
        });
      }
    });

    if (game.participantUnions.length === 0) {
      game.participantUnions.push({
        id: new Date().getMilliseconds(),
        participants: [],
        conjunction: Conjunction.And,
        childrenConjunction: Conjunction.And,
      });
    }

    [...game.gameParticipants.map(p => p.participant.id), null].forEach(id => {
      if (game.rewardUnions.filter(ru => ru.participantId === id).length === 0) {
        game.rewardUnions.push({
          id: new Date().getMilliseconds(),
          participantId: id,
          rewards: [],
          currency: null,
          experience: null,
          rankFrom: isGameMode ? null : 1,
          rankTo: isGameMode ? null : 100,
        });
      }
    });

    this.setState({ game });
  };

  handleSaveAndPublish = () => {
    const { game } = this.state;
    const {
      id, copy, requestPost, requestPut,
    } = this.props;

    confirm().then(() => {
      if (id && !copy) {
        requestPut(id, game, true);
      } else {
        requestPost(game, true);
      }
    }, NoCancel);
  };

  handleUnpublish = () => {
    const {
      id, requestUnpublish,
    } = this.props;

    confirm().then(() => {
      if (id) {
        requestUnpublish(id);
      }
    }, NoCancel);
  };

  handleSettingSave = game => {
    this.setState({ game });
    this.saveGame();
  }

  saveGame = () => {
    const { game } = this.state;
    const {
      id, copy, requestPost, requestPut,
    } = this.props;

    if (id && !copy) {
      requestPut(id, game, false);
    } else {
      requestPost(game, false);
    }
  }

  handleModeSwitch = () => {
    const { advancedMode } = this.state;

    this.addMissingUnions();
    this.setState({ advancedMode: !advancedMode });
  };

  handleDisableSwitch = () => {
    const { game } = this.state;
    const { id, requestEnable, requestDisable } = this.props;

    if (game.publishedOn) {
      if (game.isDisabled) {
        requestEnable(id);
      } else {
        requestDisable(id);
      }
    }
  };

  removeFromGame = (array, element) => {
    const { game } = this.state;

    const index = array.indexOf(element);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ game });
    }
  };

  gameRequiresAdvancedMode = game => {
    let result = false;

    // more than 1 participant union
    if (game.participantUnions.length > 1) result = true;
    // more than 1 rule union per player profile
    game.ruleUnions.forEach(element => {
      if (game.ruleUnions.filter(ru => ru.participantId === element.participantId).length > 1) { result = true; }
    });
    // more than 1 reward union per player profile
    game.rewardUnions.forEach(element => {
      if (game.rewardUnions.filter(ru => ru.participantId === element.participantId).length > 1) { result = true; }
    });

    return result;
  };

  hasAnyRule = () => {
    const { game } = this.state;

    let result = false;
    game.ruleUnions.forEach(union => {
      if (union.rules.length > 0) result = true;
      if (union.games.length > 0) result = true;
    });
    return result;
  };

  handleAddGameElement = (array, gameElement, copy) => {
    this.setState({ gameElementToAdd: gameElement, arrayToAdd: array, elementToEdit: copy });
  };

  handleClickGameElement = (element, gameElement) => {
    this.setState({ gameElementToAdd: gameElement, elementToEdit: element, arrayToAdd: null });
  };

  handleClickGameNotification = (notification, type) => {
    this.setState({ gameNotificationToEdit: { notification, type } });
  };

  handleImageChanged = imageId => {
    const { game } = this.state;
    const activeParticipant = game.gameParticipants.find(p => p.participant.isActive) ?? null;

    if (activeParticipant) { activeParticipant.imageId = imageId; } else { game.imageId = imageId; }

    this.setState({ game });
  }

  handleSimulateAction = actions => {
    const { game } = this.state;
    const { requestSimulate } = this.props;

    requestSimulate(game, actions);
    this.setState({ simulate: false, simulatedActions: actions });
  };

  handleAddProfile = participant => {
    const { game } = this.state;

    game.gameParticipants.forEach(p => p.participant.isActive = false);
    participant.isActive = true;
    game.gameParticipants.push({
      id: new Date().getMilliseconds(), name: '', participant, attributes: {},
    });

    this.setState({ participantSelectionIsOpen: false, game }, this.addMissingUnions);
  }

  handleSelectProfile = participant => {
    const { game } = this.state;

    game.gameParticipants.forEach(p => p.participant.isActive = false);
    participant.isActive = true;

    this.setState({ game });
  }

  handleRemoveProfile = participant => {
    const { game } = this.state;

    game.gameParticipants = game.gameParticipants.filter(p => p.participant.id !== participant.id);

    game.gameNotifications = game.gameNotifications.filter(x => x.participantId !== participant.id);

    this.setState({ game }, () => (participant.isActive && game.gameParticipants.length > 0 ? this.handleSelectProfile(game.gameParticipants[0].participant) : null));
  }

  render() {
    const {
      id, copy, gameMode, requestDelete, onBackClick, isGameMode, allRules, allRankings, allRewards, allParticipants
    } = this.props;
    const {
      game,
      advancedMode,
      gameElementToAdd,
      elementToEdit,
      arrayToAdd,
      gameNotificationToEdit,
      simulate,
      simulatedActions,
      section,
      participantSelectionIsOpen,
    } = this.state;

    const { simulation } = this.props;

    const activeParticipant = game.gameParticipants.find(p => p.participant.isActive) ?? null;

    const gameNotifications = game.gameNotifications.filter(x => (x.type === 1 || x.type === 2) && ((!activeParticipant && !x.participantId) || (activeParticipant && x.participantId === activeParticipant.participant.id)));

    return (
      <>
        <AnimatePresence>
          {!gameElementToAdd
            && !gameNotificationToEdit
            && !simulate && (
            <animate.game key="main-pose">
              <div id="dataForm" className="game-editor">
                <div className="row m-b-sm">
                  <div className="col-md-12">
                    <span className="page-title hex-img">
                      {id && !copy && `Edit ${isGameMode ? 'Game' : 'Leaderboard'}`}
                      {id && copy && `Copy ${isGameMode ? 'Game' : 'Leaderboard'}`}
                      {!id && `New ${isGameMode ? 'Game' : 'Leaderboard'}`}
                    </span>
                    <span className="sub-heading hex-img">
                      {
                        isGameMode ? 'Game is an activity based on player actions that player can complete and get rewarded'
                          : 'Leaderboard is a scoreboard for displaying the ranking of the players in a competitive event'
                      }
                    </span>
                    <div className="pageStyle">
                      <BackButton onClick={onBackClick} />
                      {/* { isGameMode &&
                        <button type="button" className="active"
                          onClick={() => this.setState({ simulate: true })}>
                          <i className="glyphicon glyphicon-plane" />
                          <div className="hover-text">Simulate</div>
                        </button>
                      } */}
                      {id && !copy && !gameMode && <CopyButton to={`${id}/copy`} />}
                      {id && !copy && !gameMode && !game.publishedOn && <DeleteButton onClick={() => requestDelete(id)} />}
                      {<SaveButton onClick={this.saveGame} disabled={!game.name} />}
                      {!game.publishedOn && (
                        <button type="button"
                          className="active"
                          disabled={!game.name || (!isGameMode && !this.hasAnyRule())}
                          onClick={this.handleSaveAndPublish}>
                          <i className="glyphicon glyphicon-floppy-open" />
                          <div className="hover-text">Publish</div>
                        </button>
                      )}
                      {game.publishedOn && (
                        <button type="button"
                          className="delete-btn"
                          disabled={game.roundCount > 0}
                          onClick={this.handleUnpublish}>
                          <i className="glyphicon glyphicon-floppy-save" />
                          <div className="hover-text">Unpublish</div>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row p-l-md">
                  <div className="col-md-12">
                    <div className="panel panel-default hex">
                      <div className="octomask">
                        <ImageUpload imageIds={activeParticipant ? [activeParticipant.imageId] : [game.imageId]}
                          onImageChange={this.handleImageChanged}
                          single />
                      </div>
                      <div className="panel-heading" />
                      <div className="panel-menu">
                        <div className={section === 'general' ? 'active' : ''}>
                          <button type="button"
                            onClick={() => { this.setState({ section: 'general' }); }}
                            className="glyphbutton"
                            title="General">
                            <i className="glyphicon glyphicon-home" />
                          </button>
                        </div>
                        <div className={section === 'journey' ? 'active' : ''}>
                          <button type="button"
                            onClick={() => { this.setState({ section: 'journey' }); }}
                            className="glyphbutton"
                            title="Journey">
                            <i className="glyphicon glyphicon-grain" />
                          </button>
                        </div>
                        <div className={section === 'settings' ? 'active' : ''}>
                          <button type="button"
                            onClick={() => { this.setState({ section: 'settings' }); }}
                            className="glyphbutton"
                            title="Settings">
                            <i className="glyphicon glyphicon-cog" />
                          </button>
                        </div>
                        {
                          id && game && game.publishedOn
                          && (
                            <div className={section === 'rounds' ? 'active' : ''}>
                              <button type="button"
                                onClick={() => this.setState({ section: 'rounds' })}
                                className="glyphbutton"
                                title="Rounds">
                                <i className={`glyphicon glyphicon-${helper.gameResolver(this.props, 'fire', 'stats')}`} />
                              </button>
                            </div>
                          )
                        }
                      </div>
                      {
                        (section === 'general' || section === 'settings')
                        && (
                          <div className="profile-selector">
                            {
                              game.gameParticipants.length > 0
                            && (
                              <div className={activeParticipant ? '' : 'active'}>
                                <button type="button" title="Player Profile"
                                  className="btn-profile"
                                  onClick={() => this.handleSelectProfile({})}>
                                  Default
                                </button>
                              </div>
                            )
                            }
                            { game.gameParticipants.map(p => (
                              <React.Fragment key={p.participant.id}>
                                <i className="glyphicon glyphicon-arrow-left" />
                                <div className={p.participant.isActive ? 'active' : ''}>
                                  <button type="button" title="Player Profile"
                                    className="btn-profile"
                                    onClick={() => this.handleSelectProfile(p.participant)}>
                                    <Image src={GetImageSrc(p.participant.imageId, 40)} alt="participant" />
                                    {p.participant.name}
                                  </button>
                                  {p.participant.id && !game.publishedOn
                                    && (
                                      <button type="button" className="btn-profile-remove"
                                        title="Remove Player Profile" onClick={() => this.handleRemoveProfile(p.participant)}>
                                        <i className="glyphicon glyphicon-remove" />
                                      </button>
                                    )}
                                </div>
                              </React.Fragment>
                            ))}
                            {
                              !game.publishedOn
                            && (
                              <div>
                                <button type="button" className="btn-profile"
                                  title="Add Player Profile" onClick={() => this.setState({ participantSelectionIsOpen: true })}><i className="glyphicon glyphicon-plus" />
                                </button>
                              </div>
                            )
                            }
                          </div>
                        )
                      }
                      <GameElementSelector isOpen={participantSelectionIsOpen}
                        mode={GameElement.Participants}
                        existing={game.gameParticipants.map(p => p.participant)}
                        multiple={false}
                        onClose={() => this.setState({ participantSelectionIsOpen: false })}
                        onSelect={participant => this.handleAddProfile(participant)} />

                      {section === 'general' && game && (
                        <div className="panel-body hex" id="scroller">
                          <label className="game-switch" htmlFor="mode-switch">
                            Advanced Mode
                            <div style={{ textAlign: 'right' }}>
                              <Switch id="game-switch"
                                onChange={this.handleModeSwitch}
                                disabled={this.gameRequiresAdvancedMode(game)}
                                checked={advancedMode}
                                checkedChildren={<i className="glyphicon glyphicon-ok" />}
                                unCheckedChildren={<i className="glyphicon glyphicon-remove" />} />
                            </div>
                          </label>
                          <div className="row game-tree">
                            <div className="col-xs-3" />
                            <div className="col-xs-6" style={{ textAlign: 'center', borderBottom: '1px solid white' }}>
                              <button type="button"
                                className={`${!game.name && !activeParticipant ? 'error' : ''} game ${helper.simulationStatusText(simulation)}`}
                                title={simulation ? helper.simulationStatusText(simulation) : 'Settings'}
                                onClick={() => this.setState({ section: 'settings' })}>
                                <div className="game-title">
                                  <i className="glyphicon glyphicon-fire" />
                                  {game.name ? game.name : 'UNNAMED GAME'}
                                  <Popup trigger={(
                                    <button type="button"
                                      className={`btn-notification ${gameNotifications.length > 0 ? 'active' : ''}`}>
                                      <i className="glyphicon glyphicon-bell" />
                                      <span>{gameNotifications.length}</span>
                                    </button>
                                  )}
                                    on={['click']}
                                    position="right center"
                                    closeOnDocumentClick
                                    className="reward notifications">
                                    <div className="union-title">
                                      <i className="glyphicon glyphicon-bell" />
                                      Game Notifications
                                    </div>
                                    <ul className="tree">
                                      {
                                        gameNotifications.map(notification => (
                                          <li key={notification.name}>
                                            <button onClick={() => this.handleClickGameNotification(notification)} type="button"
                                              className="rule">
                                              <Image src={GetImageSrc(notification.imageId, 40)} alt="notification" />
                                              {notification.name}
                                            </button>
                                            <button type="button"
                                              className="btn-remove"
                                              title="Delete"
                                              onClick={() => this.removeFromGame(game.gameNotifications, notification)}>
                                              <i className="glyphicon glyphicon-remove" />
                                            </button>
                                          </li>
                                        ))
                                      }
                                    </ul>
                                    {
                                      !gameNotifications.find(x => x.type === 1)
                                      && (
                                        <ul className="tree subtree">
                                          <li>
                                            <button type="button" className="rule-new"
                                              onClick={() => this.handleClickGameNotification(null, 1)}>
                                              Add Round Started...
                                            </button>
                                          </li>
                                        </ul>
                                      )
                                    }
                                    {
                                      !gameNotifications.find(x => x.type === 2)
                                      && (
                                        <ul className="tree subtree">
                                          <li>
                                            <button type="button" className="rule-new"
                                              onClick={() => this.handleClickGameNotification(null, 2)}>
                                              Add Round Completed...
                                            </button>
                                          </li>
                                        </ul>
                                      )
                                    }
                                  </Popup>
                                  <div className="error">
                                    <div>{!game.name && !activeParticipant ? 'Game must contain a name!' : ''}</div>
                                  </div>
                                </div>
                              </button>
                            </div>
                            <div className="col-xs-3" />
                          </div>
                          <div className="row game-tree">
                            <div className="col-xs-3" style={{ textAlign: 'right' }}>
                              <span style={{
                                borderLeft: '1px solid white',
                                borderTop: '1px solid white',
                                padding: '0 40px',
                              }} />
                            </div>
                            {isGameMode && (
                              <div className="col-xs-6" style={{ textAlign: 'center' }}>
                                <span style={{
                                  borderRight: '1px solid white'
                                }} />
                              </div>
                            )}
                            {!isGameMode && (
                              <div className="col-xs-3" style={{ textAlign: 'center' }}>
                                <span style={{
                                  borderRight: '1px solid white'
                                }} />
                              </div>
                            )}
                            {!isGameMode && (
                              <div className="col-xs-3" style={{ textAlign: 'center' }}>
                                <span style={{
                                  borderRight: '1px solid white'
                                }} />
                              </div>
                            )}
                            <div className="col-xs-3" style={{ textAlign: 'left' }}>
                              <span style={{
                                borderRight: '1px solid white',
                                borderTop: '1px solid white',
                                padding: '0 40px',
                              }} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-3">
                              <GameRewards game={game}
                                advancedMode={!isGameMode && advancedMode}
                                isGameMode={isGameMode}
                                onChange={() => this.setState({ game })}
                                onClick={this.handleClickGameElement}
                                onAdd={this.handleAddGameElement} />
                            </div>
                            {isGameMode && (
                              <div className="col-xs-6">
                                <GameRules game={game}
                                  advancedMode={advancedMode}
                                  onChange={() => this.setState({ game })}
                                  onClick={this.handleClickGameElement}
                                  onNotificationClick={this.handleClickGameNotification}
                                  onAdd={this.handleAddGameElement}
                                  simulation={simulation} />
                              </div>
                            )}
                            {!isGameMode && (
                              <div className="col-xs-3">
                                <GameRankings game={game}
                                  onChange={() => this.setState({ game })}
                                  onClick={this.handleClickGameElement}
                                  onAdd={this.handleAddGameElement} />
                              </div>
                            )}
                            {!isGameMode && (
                              <div className="col-xs-3">
                                <GameQualifications game={game}
                                                    onChange={() => this.setState({ game })}
                                                    onClick={this.handleClickGameElement}
                                                    onAdd={this.handleAddGameElement} />
                              </div>
                            )}
                            <div className="col-xs-3">
                              <GameParticipants game={game}
                                advancedMode={advancedMode}
                                onClick={this.handleClickGameElement}
                                onChange={() => this.setState({ game })}
                                onAdd={this.handleAddGameElement} />
                            </div>
                          </div>
                        </div>
                      )}

                      {section === 'journey' && game && (
                        <div className="panel-body hex">
                          <div style={{ position: "relative", color: "black" }}>
                            <JourneyEditor
                                initialGame={game}
                                onChange={(game) => this.setState({ game })}
                                isGameMode={isGameMode}
                                isLeaderboardMode={!isGameMode}
                                allRules={allRules}
                                allRankings={allRankings}
                                allRewards={allRewards}
                                allParticipants={allParticipants}
                            />
                          </div>
                        </div>
                      )}

                      {section === 'settings' && (
                        <div className="panel-body hex" id="scroller">
                          <div className="col-md-9">
                            <GameSettings game={game} isGameMode={isGameMode}
                              onChange={() => this.setState({ game })} />
                          </div>
                          {id && game
                            && (
                              <div className="col-md-3 form-additional-info">
                                <label>
                                  Created
                                  <div>{date.long(game.createdOn)}</div>
                                </label>
                                <label>
                                  Published
                                  <div>{date.long(game.publishedOn)}</div>
                                </label>
                                {(game.blockchainAddress)
                                && (
                                  <label>
                                    Blockchain balance
                                    <div>{game.blockchainBalance}</div>
                                  </label>
                                )}
                                {(game.blockchainAddress)
                                && (
                                  <label>
                                    Blockchain address
                                    <div>{game.blockchainAddress}</div>
                                  </label>
                                )}
                                <label htmlFor="disable-switch">
                                  Disabled
                                  <div>
                                    <Switch id="disable-switch"
                                      onChange={this.handleDisableSwitch}
                                      checked={game.isDisabled}
                                      checkedChildren={<i className="glyphicon glyphicon-ok" />}
                                      unCheckedChildren={<i className="glyphicon glyphicon-remove" />} />
                                  </div>
                                </label>
                              </div>
                            )}
                        </div>
                      )}

                      {id && section === 'rounds' && (
                        <div className="panel-body hex dataFormList">
                          <div className="form-subsection">
                            <GameRoundList id={id} match={this.props.match} />
                          </div>
                        </div>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </animate.game>
          )}
          {gameElementToAdd && (
            <animate.gameEditor key="editor-pose">
              <GameElementEditors gameElement={gameElementToAdd}
                id={elementToEdit ? elementToEdit.id : null}
                copy={!!(elementToEdit && arrayToAdd)}
                match={this.props.match}
                onBackClick={() => this.setState({ gameElementToAdd: null, arrayToAdd: null, elementToEdit: null })} />
            </animate.gameEditor>
          )}
          {isGameMode && simulate && (
            <animate.gameEditor key="action-pose">
              <GameSimulation game={game}
                actions={simulatedActions}
                onBackClick={() => this.setState({ simulate: false })}
                onSaveClick={values => this.handleSimulateAction(values)} />
            </animate.gameEditor>
          )}
          {isGameMode && gameNotificationToEdit && (
            <animate.gameEditor key="action-pose">
              <GameRuleNotification notification={gameNotificationToEdit.notification}
                type={gameNotificationToEdit.type}
                game={game}
                onBackClick={() => this.setState({ gameNotificationToEdit: null })}
                onSaveClick={() => this.setState({ gameNotificationToEdit: null })}
                onDeleteClick={() => this.setState({ gameNotificationToEdit: null })} />
            </animate.gameEditor>
          )}
        </AnimatePresence>
      </>
    );
  }
}

GameEdit.propTypes = {
  id: PropTypes.number,
  isGameMode: PropTypes.bool.isRequired,
  copy: PropTypes.bool,
  gameMode: PropTypes.bool,
  game: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  reward: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  rule: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  participant: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  requestGet: PropTypes.func.isRequired,
  requestPost: PropTypes.func.isRequired,
  requestPut: PropTypes.func.isRequired,
  requestDelete: PropTypes.func.isRequired,
  requestEnable: PropTypes.func.isRequired,
  requestDisable: PropTypes.func.isRequired,
  requestUnpublish: PropTypes.func.isRequired,
  requestSimulate: PropTypes.func,
  hideChildFilters: PropTypes.func.isRequired,
  simulation: PropTypes.shape({}),
  onBackClick: PropTypes.func,
};

GameEdit.defaultProps = {
  id: null,
  copy: false,
  gameMode: false,
  game: null,
  reward: null,
  rule: null,
  participant: null,
  simulation: null,
  onBackClick: null,
  requestSimulate: null,
};

export default connect(
  (state, ownProps) => ({
    id: helper.getIdFromProps(ownProps),
    isGameMode: helper.gameResolver(ownProps, true, false),
    copy: helper.getCopyFromProps(ownProps),
    game: helper.gameResolver(ownProps, state.game.details[helper.getIdFromProps(ownProps)], state.leaderboard.details[helper.getIdFromProps(ownProps)]),
    simulation: state.game.simulation,
    reward: state.reward.currentInGame,
    rule: state.rule.currentInGame,
    ranking: state.ranking.currentInGame,
    participant: state.participant.currentInGame,
    allRules: state.rule.all,
    allRankings: state.ranking.all,
    allRewards: [ { id: 'currency', name: 'Currency' }, { id: 'experience', name: 'Experience' }, ...state.reward.all ],
    allParticipants: state.participant.all,
  }),
  (dispatch, ownProps) => bindActionCreators(
    {
      ...helper.gameResolver(ownProps, gameActionCreators, leaderboardActionCreators),
      requestAllRules: ruleActionCreators.requestAll,
      requestAllRankings: rankingActionCreators.requestAll,
      requestAllRewards: rewardActionCreators.requestAll,
      requestAllParticipants: participantActionCreators.requestAll,
    },
    dispatch,
  ),
)(GameEdit);
